<template>
  <div v-if="wishlistedTripDetails" class="l-grid" data-cy="wishlist-card-list">
    <template v-for="(tripDetail, index) in wishlistedTripDetails">
      <WishlistTripDetailsCard
        :key="index"
        class="l-grid__cell l-grid__cell--4-col"
        v-bind="tripDetail"
        @status="getWishlistCardStatus"
      />
    </template>
    <div v-if="hasMoreTrips" class="l-grid__cell l-grid__cell--12-col">
      <Button
        class="button--secondary button--block"
        data-cy="view-more-trips"
        @click.native="viewMoreTrips"
        >View more trips</Button
      >
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import WishlistTripDetailsCard from "../WishlistTripDetailsCard/WishlistTripDetailsCard.vue";
import { WishlistCardListProps } from "./Props";

export default Vue.extend({
  name: "WishlistCardList",
  components: {
    WishlistTripDetailsCard,
    Button,
  },
  props: {
    wishlistedTripDetails: {
      type: Array as PropType<WishlistCardListProps["wishlistedTripDetails"]>,
      required: false,
      default() {
        return [];
      },
    },
    hasMoreTrips: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    viewMoreTrips() {
      this.$emit("view-more-trips");
    },
    getWishlistCardStatus(status: boolean) {
      this.$emit("status", status);
    },
  },
});
</script>
