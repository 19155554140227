import Vue from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import Modal from "atlas/src/components/Modal/Modal.vue";
import Imagery from "atlas/src/components/Imagery/Imagery.vue";
import Alert from "atlas/src/components/Alert/Alert.vue";
import WishlistCardList from "../WishlistCardList/WishlistCardList.vue";
import Page from "~/components/Page/Page.vue";
import { getItemFromLocalStorage, removeItemFromLocalStorage } from "~~/lib/utils/manageLocalStorageItems";
import { fetchParser } from "~/lib/utils/datetime/dateTimeReviver";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";
const logger = loggerFactory({
  tags: [logTags.Layer.Component, logTags.Page.Wishlist]
});
const TRIP_CARDS_PER_BATCH = 6;
export default Vue.extend({
  name: "WishlistPage",
  components: {
    Page,
    Button,
    Icon,
    WishlistCardList,
    Imagery,
    Modal,
    Alert
  },
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loggedIn: false,
      wishlistTripCodes: [],
      products: [],
      noOfPage: 1,
      currentPage: 1,
      hasMoreTrips: false,
      showRemoveAllTripsModal: false,
      formattedWishlistItems: {},
      auth0Id: null,
      wishlistStoreCount: 0
    };
  },
  computed: {
    isLoggedIn() {
      return this.loggedIn;
    },
    productList() {
      return this.products;
    },
    snapshotHeaderText() {
      return this.isLoggedIn ? "Choose your next adventure" : "Start creating your wishlist";
    },
    snapshotLeadText() {
      return this.isLoggedIn ? "Compare your favourites to find the right trip for you" : "Explore trips and add your favourites for later";
    }
  },
  async mounted() {
    var _this$$auth, _strategy$token, _strategy$token2, _strategy$token3;
    const strategy = (_this$$auth = this.$auth) === null || _this$$auth === void 0 ? void 0 : _this$$auth.strategy;
    const token = strategy === null || strategy === void 0 ? void 0 : (_strategy$token = strategy.token) === null || _strategy$token === void 0 ? void 0 : _strategy$token.get();
    const isTokenExpired = strategy === null || strategy === void 0 ? void 0 : (_strategy$token2 = strategy.token) === null || _strategy$token2 === void 0 ? void 0 : _strategy$token2.status().expired();
    const isValid = strategy === null || strategy === void 0 ? void 0 : (_strategy$token3 = strategy.token) === null || _strategy$token3 === void 0 ? void 0 : _strategy$token3.status().valid();
    this.loggedIn = !!(token && isValid && !isTokenExpired);
    if (!this.loggedIn) {
      const keysToRemove = ["auth_firstname", "expires_at", "auth0_id", "id_token", "access_token", "auth_lastname", "auth_email"];
      for (const key of keysToRemove) {
        await removeItemFromLocalStorage(key);
      }
    } else {
      this.auth0Id = getItemFromLocalStorage("auth0_id");
    }
    await this.updateWishlistItems(this.loggedIn);
    await this.updateWishlistPage();
  },
  methods: {
    async updateWishlistItems(isValidToken) {
      await this.initializeStore();
      isValidToken ? await this.getAllWishlistedItems() : await this.removeSalesforceItemsFromStore();
    },
    updateWishlistPage() {
      this.wishlistTripCodes = this.getWishlist();
      this.products = [];
      if (this.wishlistTripCodes && this.wishlistTripCodes.length) {
        this.viewTrips().catch(err => logger.error("Cannot viewTrips after updatingWishlistPage", {
          error: err
        }));
      }
    },
    async initializeStore() {
      const wishlistUpdatedStatus = this.$store.getters["wishlist/getStatus"];
      if (!wishlistUpdatedStatus) {
        await this.$store.dispatch("wishlist/initialize");
      }
    },
    async openAuthPage(params) {
      await this.$auth.loginWith("auth0", {
        params
      });
    },
    async logOut() {
      this.loggedIn = false;
      const keysToRemove = ["auth_firstname", "expires_at", "auth0_id", "id_token", "access_token", "auth_lastname", "auth_email", "wishlist"];
      for (const key of keysToRemove) {
        await removeItemFromLocalStorage(key);
      }
      await this.$auth.logout();
    },
    async removeSalesforceItemsFromStore() {
      const wishlist = this.$store.getters["wishlist/getWishlistItems"] || {};
      if (Object.values(wishlist).length > 0) {
        const wishlistItems = Object.values(wishlist).filter(wishlistItem => {
          return wishlistItem.id === null;
        });
        this.formattedWishlistItems = wishlistItems.reduce((accumulator, item) => (
        // eslint-disable-next-line no-sequences
        accumulator[item.productCode] = item, accumulator), {});
        await this.$store.dispatch("wishlist/setWishlist", this.formattedWishlistItems);
      }
    },
    getWishlist() {
      let result = [];
      try {
        const wishlist = this.$store.getters["wishlist/getWishlistItems"] || {};
        this.wishlistStoreCount = this.$store.getters["wishlist/getWishlistCount"] || 0;
        if (wishlist && Object.keys(wishlist).length > 0) {
          result = Object.keys(wishlist);
        }
      } catch (error) {
        logger.error("Getting wishlist from store failed", error);
      }
      return result;
    },
    async fetchProducts(productCodes) {
      const result = await $fetch("/api/nuxt/product/wishlist", {
        params: {
          productCodes,
          locale_iso: this.$i18n.localeProperties.iso,
          locale_code: this.$i18n.locale,
          locale_domain: useRuntimeConfig().public.baseUrl,
          path: this.$route.path,
          slug: `/${this.$route.params.pathMatch}`,
          currency_code: this.$route.params.currencyCode || this.$i18n.localeProperties.currencyCode
        },
        parseResponse: fetchParser
      });
      if (result && result.wishlistedTripDetails) {
        return result.wishlistedTripDetails;
      }
      return [];
    },
    checkViewMoreStatus() {
      if (this.wishlistTripCodes) {
        this.noOfPage = Math.ceil(this.wishlistTripCodes.length / TRIP_CARDS_PER_BATCH);
        if (this.noOfPage > this.currentPage) {
          this.hasMoreTrips = true;
        } else {
          this.hasMoreTrips = false;
        }
      }
    },
    async viewMoreTrips() {
      this.currentPage++;
      await this.updateWishlistPage();
    },
    async viewTrips() {
      this.checkViewMoreStatus();
      if (this.wishlistTripCodes) {
        const batch = this.wishlistTripCodes.slice(0, this.currentPage * TRIP_CARDS_PER_BATCH);
        const productList = await this.fetchProducts(batch.join(","));
        this.products = productList.sort((a, b) => batch.indexOf(a === null || a === void 0 ? void 0 : a.code) - batch.indexOf(b === null || b === void 0 ? void 0 : b.code));
      }
    },
    showRemoveAllWishlistModal() {
      this.showRemoveAllTripsModal = true;
    },
    hideRemoveAllWishlistModal() {
      this.showRemoveAllTripsModal = false;
    },
    async clearWishlist() {
      this.products = [];
      this.wishlistTripCodes = [];
      await this.$store.dispatch("wishlist/setWishlist", {});
      this.wishlistStoreCount = 0;
    },
    async removeAllWishlistItems() {
      if (!this.isLoggedIn) {
        await this.clearWishlist();
      } else {
        const wishlist = this.$store.getters["wishlist/getWishlistItems"] || {};
        if (wishlist) {
          const wishlistIds = Object.values(wishlist).map(item => {
            return item.id;
          }).filter(Boolean);
          if (wishlistIds.length) {
            await $fetch("/api/nuxt/wishlist/remove-all", {
              method: "PUT",
              body: {
                wishlistIds
              }
            }).then(() => {
              this.clearWishlist().catch(error => logger.error("An exception occurred in clear the wishlist", {
                error
              }));
            });
          }
        }
      }
      this.hideRemoveAllWishlistModal();
    },
    async getWishlistPageStatus(status) {
      if (!status) {
        await this.updateWishlistPage();
      }
    },
    async getAllWishlistedItems() {
      if (this.auth0Id) {
        try {
          this.formattedWishlistItems = {};
          const response = this.auth0Id ? await $fetch("/api/nuxt/wishlist/items", {
            method: "GET",
            params: {
              correlationID: this.auth0Id,
              cache: Date.now()
            }
          }) : null;
          const salesforceItems = response ? response.wishlist : [];
          this.formattedWishlistItems = salesforceItems.map(item => {
            return {
              productCode: item.code,
              id: item.id,
              productName: item.name
            };
          }).reduce((accumulator, item) => (
          // eslint-disable-next-line no-sequences
          accumulator[item.productCode] = item, accumulator), {});
        } catch (error) {
          logger.error("getAllWishlistedItems failed", error);
        }
        await this.$store.dispatch("wishlist/setWishlist", this.formattedWishlistItems);
      }
    }
  }
});