<template>
  <TripDetailsCard
    v-bind="{
      ...$props,
      link: $link.create($props.url),
      routerLink: $link.getIsAllowedAsNuxtLinks($props.url),
    }"
  >
    <WishlistButton
      :product-code="code"
      :product-name="name"
      :full-width="true"
      @status="getWishlistButtonStatus"
    >
    </WishlistButton>
  </TripDetailsCard>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import TripDetailsCard from "atlas/src/components/TripDetailsCard/TripDetailsCard.vue";
import { WishlistTripDetailsCardProps } from "./Props";
import WishlistButton from "~/components/WishlistButton/WishlistButton.vue";

export default Vue.extend({
  name: "WishlistTripDetailsCard",
  components: {
    TripDetailsCard,
    WishlistButton,
  },
  props: {
    name: {
      type: String as PropType<WishlistTripDetailsCardProps["name"]>,
      required: false,
      default: null,
    },
    code: {
      type: String as PropType<WishlistTripDetailsCardProps["code"]>,
      required: false,
      default: null,
    },
    duration: {
      type: Number as PropType<WishlistTripDetailsCardProps["duration"]>,
      required: false,
      default: null,
    },
    styles: {
      type: Array as PropType<WishlistTripDetailsCardProps["styles"]>,
      required: false,
      default: null,
    },
    destinations: {
      type: Array as PropType<WishlistTripDetailsCardProps["destinations"]>,
      required: false,
      default: null,
    },
    price: {
      type: Object as PropType<WishlistTripDetailsCardProps["price"]>,
      required: false,
      default: null,
    },
    startCity: {
      type: String as PropType<WishlistTripDetailsCardProps["startCity"]>,
      required: false,
      default: null,
    },
    endCity: {
      type: String as PropType<WishlistTripDetailsCardProps["endCity"]>,
      required: false,
      default: null,
    },
    reviewRating: {
      type: Number as PropType<WishlistTripDetailsCardProps["reviewRating"]>,
      required: false,
      default: null,
    },
    physicalRating: {
      type: Number as PropType<WishlistTripDetailsCardProps["physicalRating"]>,
      required: false,
      default: null,
    },
    url: {
      type: String as PropType<WishlistTripDetailsCardProps["url"]>,
      required: false,
      default: null,
    },
    map: {
      type: Object as PropType<WishlistTripDetailsCardProps["map"]>,
      required: false,
      default: null,
    },
  },
  methods: {
    getWishlistButtonStatus(status: boolean) {
      this.$emit("status", status);
    },
  },
});
</script>
